import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Menu from "./components/Menu";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { connect } from "./data/connect";
import { AppContextProvider } from "./data/AppContext";
import {
  setIsLoggedIn,
  setUsername,
  loadUserData,
} from "./data/user/user.actions";
import Account from "./pages/Account";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { PracticeSession } from "./models/PracticeSession";
import RedirectToLogin from "./components/RedirectToLogin";
import PracticeSessionPage from "./pages/PracticeSessionPage";
import PracticeSessionListPage from "./pages/PracticeSessionListPage";
import PracticeTemplateListingPage from "./pages/PracticeTemplateListingPage";
import Navbar from "./components/Navbar/Navbar";
import { SSLProvider } from "./context/SSLContext";
import Home from "./pages/Home";
import "./App.css";
import ExploreMorePage from "./pages/OpenDashboard/Dashboard";

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <SSLProvider>
        <IonReactRouter basename={process.env.PUBLIC_URL}>
          <IonicAppConnected />
        </IonReactRouter>
      </SSLProvider>
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  schedule: PracticeSession;
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
}

interface IonicAppProps extends StateProps, DispatchProps {}

setupIonicReact();

const IonicApp: React.FC<IonicAppProps> = ({
  darkMode,
  setIsLoggedIn,
  setUsername,
  loadUserData,
}) => {
  useEffect(() => {
    loadUserData();
    // eslint-disable-next-line
  }, []);

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    // Define the API endpoint URL
    const apiUrl = "/papi/institution/user";

    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((responseData) => {
        setData(responseData);
      })
      .catch((err) => {
        console.error("Error while fetching data from the API.", err);
      });
  }, []);

  return (
    <IonApp className={darkMode ? "dark-theme" : ""}>
      <RouterWrapper
        darkMode={darkMode}
        setIsLoggedIn={setIsLoggedIn}
        setUsername={setUsername}
      />
    </IonApp>
  );
};

const RouterWrapper: React.FC<{
  darkMode: boolean;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
}> = ({ darkMode, setIsLoggedIn, setUsername }) => {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/home" || location.pathname === "/" ? null : (
        <Navbar darkMode={darkMode} title="Wordline" />
      )}
      <IonContent>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/home" component={Home} exact />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route
            path="/logout"
            render={() => {
              return (
                <RedirectToLogin
                  setIsLoggedIn={setIsLoggedIn}
                  setUsername={setUsername}
                />
              );
            }}
          />
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Route
                path="/PracticeSession/Start/:id"
                component={PracticeSessionPage}
              />
              <Route path="/OpenDashboard" component={ExploreMorePage} />

              <Route
                path="/PracticeSession/List"
                component={PracticeSessionListPage}
              />
              <Route
                path="/PracticeTemplates/Student/List"
                component={PracticeTemplateListingPage}
              />
              <Route
                path="/PracticeTemplates/List"
                component={PracticeTemplateListingPage}
              />
              <Route path="/account" component={Account} />
            </IonRouterOutlet>
          </IonSplitPane>
        </Switch>
      </IonContent>
    </>
  );
};

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    schedule: state.data.schedule,
  }),
  mapDispatchToProps: {
    loadUserData,
    setIsLoggedIn,
    setUsername,
  },
  component: IonicApp,
});
