import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonBadge,
  IonSpinner,
} from "@ionic/react";
import swal from "sweetalert";
import { connect } from "../data/connect";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router";
import { useUser } from "../context/UserContext";
import Pagination from "../components/CustomPagination/Pagination";
import "./PracticeSessionPage.scss";
import { fetchPracticeTemplateData } from "../papiclient/client";
// @ts-ignore
import Icon from "@mdi/react";
// @ts-ignore
import { mdiViewDashboard } from "@mdi/js";

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  darkMode: boolean;
}
export interface PracticeTemplateData {
  id: number;
  name: string;
  topicName: string;
  examNames: string[];
  totalQuestions: number;
  totalSections: number;
  status: string;
  liveExamEndsAt: Date | null;
  liveExamStartsAt: Date | null;
  liveExamLength: number | null;
  practiceSessions?: {
    id: number;
    status: string;
    learning_mode: string;
  }[];
}

interface StatusBadgeProps {
  ptInfo: PracticeTemplateData;
}

interface ActionsProps {
  ptInfo: PracticeTemplateData;
  liveExamLength: number | null;
  setPracticeTemplateData: React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>
}

const obsoletePracticeTemplate = async (ptId:number,setPracticeTemplateData:React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) => {
  try {
    const response = await fetch(`/papi/institution/practice-template/${ptId}/obsolete`, {
      method: 'PUT',
    });
    const data = await response.json();

    if (data && data.success) {
      fetchPracticeTemplateData(setPracticeTemplateData)
      swal({
        title: "Done!",
        text: "Your practice template has been obsoleted.",
        icon: "success",
      });
    } else {
      throw new Error(data.message || 'Failed to obsolete the template');
    }
  } catch (error: unknown) {
    let errorMessage = 'An unknown error occurred';

    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else if (error && typeof error === 'object') {
      errorMessage = JSON.stringify(error);
    }

    swal({
      title: "Error!",
      text: `Failed to obsolete the practice template: ${errorMessage}`,
      icon: "error",
    });
  }
  }

  const obsoleteTemplate = (ptId:number,setPracticeTemplateData:  React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) =>{
  swal({
    title: "Are you sure?",
    text: "You are about to an obsolete a practice template.",
    icon: "warning",
    buttons: {
      cancel: {
        text: "Cancel",
        value: null,
        visible: true,
        className: "cancel-btn",
        closeModal: true,
      },
      confirm: {
        text: "Yes, Continue!",
        value: true,
        visible: true,
        className: "confirm-btn",
        closeModal: true,
      },
    },
  }).then((value) => {
    if (value) {
      obsoletePracticeTemplate(ptId, setPracticeTemplateData);
    }
  });
};

const releasePracticeTemplate = async (ptId:number,setPracticeTemplateData:  React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) => {
  try {
    const response = await fetch(`/papi/institution/practice-template/${ptId}/release`, {
      method: 'POST',
    });
    const data = await response.json();

    if (data && data.success) {
      fetchPracticeTemplateData(setPracticeTemplateData)
      swal({
        title: "Done!",
        text: "Your practice template has been released.",
        icon: "success",
      });
    } else {
      throw new Error(data.message || 'Failed to release the template');
    }
  } catch (error: unknown) {
    let errorMessage = 'An unknown error occurred';

    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else if (error && typeof error === 'object') {
      errorMessage = JSON.stringify(error);
    }

    swal({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
  }

const releaseTemplate = (ptId:number,setPracticeTemplateData:React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) =>{
  swal({
    title: "Are you sure?",
    text: "This template will get released to the students and you will not be able to make any further changes to it.",
    icon: "warning",
    buttons: {
      cancel: {
        text: "Cancel",
        value: null,
        visible: true,
        className: "cancel-btn",
        closeModal: true,
      },
      confirm: {
        text: "Yes, Continue!",
        value: true,
        visible: true,
        className: "confirm-btn",
        closeModal: true,
      },
    },
  }).then((value) => {
    if (value) {
      releasePracticeTemplate(ptId,setPracticeTemplateData);
    }
  });
};

const deletePracticeTemplate = async (ptId: number, setPracticeTemplateData: React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) => {
  try {
    const response = await fetch(`/papi/institution/practice-template/${ptId}/delete`, {
      method: 'DELETE',
    });
    const data = await response.json();

    if (data && data.success) {
      fetchPracticeTemplateData(setPracticeTemplateData)
      swal({
        title: "Done!",
        text: "Your practice template has been removed.",
        icon: "success",
      });
    } else {
      throw new Error(data.message || 'Failed to delete the practice');
    }
  } catch (error: unknown) {
    let errorMessage = 'An unknown error occurred';

    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else if (error && typeof error === 'object') {
      errorMessage = JSON.stringify(error);
    }

    swal({
      title: "Error!",
      text: errorMessage,
      icon: "error",
    });
  }
}

const confirmAndDeletePracticeTemplate = (ptId: number, setPracticeTemplateData: React.Dispatch<React.SetStateAction<PracticeTemplateData[]>>) => {
  swal({
    title: "Are you sure?",
    text: "You are about to delete a practice template.",
    icon: "warning",
    buttons: {
      cancel: {
        text: "Cancel",
        value: null,
        visible: true,
        className: "cancel-btn",
        closeModal: true,
      },
      confirm: {
        text: "Yes, Continue!",
        value: true,
        visible: true,
        className: "confirm-btn",
        closeModal: true,
      },
    },
  }).then((value) => {
    if (value) {
      deletePracticeTemplate(ptId, setPracticeTemplateData);
    }
  });
};

export const StatusBadge: React.FC<StatusBadgeProps> = ({ ptInfo }) => {
  const now = new Date();

  const isLiveExam = (ptInfo: PracticeTemplateData) => {
    return ptInfo.liveExamStartsAt !== null;
  };

  const liveExamStartsAfter = (date: Date | null) => {
    if (date === null) return false;
    return new Date(date) > now; // Exam has not started yet
  };

  const liveExamExpired = (date: Date | null) => {
    if (date === null) return false;
    return new Date(date) <= now; // Exam start time has passed, so it's expired
  };

  if (!isLiveExam(ptInfo)) {
    switch (ptInfo.status) {
      case 'released':
        return (
          <IonBadge color="success" title="All students can use this template multiple times.">
            Mock Exam Released
          </IonBadge>
        );
      case 'obsolete':
        return (
          <IonBadge color="medium" title="This mock exam has been made obsolete by your instructor and is not available for new practice anymore.">
            Obsoleted
          </IonBadge>
        );
      default:
        return (
          <IonBadge color="primary" title="All students can use this template multiple times.">
            Mock Exam Not Released
          </IonBadge>
        );
    }
  } else {
    switch (ptInfo.status) {
      case 'released':
        if (liveExamExpired(ptInfo.liveExamStartsAt)) {
          return (
            <IonBadge color="warning" title={`Exam was supposed to be conducted at ${ptInfo.liveExamStartsAt}`}>
              Exam Expired
            </IonBadge>
          );
        }
        return (
          <IonBadge color="danger" title={`Exam starts at ${ptInfo.liveExamStartsAt}`}>
            Exam Scheduled
          </IonBadge>
        );
      case 'obsolete':
        return (
          <IonBadge color="medium" title="This mock exam has been made obsolete by your instructor and is not available for new practice anymore.">
            Obsoleted
          </IonBadge>
        );
      default:
        if (liveExamStartsAfter(ptInfo.liveExamStartsAt)) {
          return (
            <IonBadge color="primary" title={`Exam is setup to start at ${ptInfo.liveExamStartsAt}`}>
              Exam Not Released
            </IonBadge>
          );
        } else {
          return (
            <IonBadge color="warning" title={`Exam was supposed to be conducted at ${ptInfo.liveExamStartsAt}`}>
              Exam Expired
            </IonBadge>
          );
        }
    }
  }
};

export const ActionBar = ({ ptInfo, setPracticeTemplateData, liveExamLength, }: ActionsProps) => {
  const history = useHistory();
  const now = new Date();
  const { user, loading } = useUser();

  // Check if it's a live exam
  const isLiveExam = (ptInfo: PracticeTemplateData) => {
    return ptInfo.liveExamStartsAt !== null;
  };

  // Check if the live exam ended
  const liveExamEndsAt = (liveExamStartTime: Date | null, liveExamEndTime: Date | null, liveExamLength: number | null) => {
    if (liveExamEndTime !== null) {
      const endTime = new Date(liveExamEndTime).getTime();
      const currentTime = new Date().getTime();
      return endTime <= currentTime;
    }

    if (liveExamStartTime !== null && liveExamLength !== null) {
      const startTime = new Date(liveExamStartTime).getTime();
      const endTime = startTime + liveExamLength * 1000;
      const currentTime = new Date().getTime();
      return endTime <= currentTime;
    }

    return false;
  };

  const liveExamStartsAt = (liveExamStartTime: Date | null) => {
    if (liveExamStartTime === null) return false;

    const startTime = new Date(liveExamStartTime).getTime();
    const currentTime = new Date().getTime();
    return startTime <= currentTime;
  };

  const isSessionCompleted = ptInfo.practiceSessions && ptInfo.practiceSessions?.[0]?.status !== 'inprogress';

  return (
    <div
      slot="end"
      className="d-flex justify-content-center"
      style={{ gap: '10px' }}
    >
      {user?.role === 'student' && (
        <>
          {isLiveExam(ptInfo) ? (
            <>
              {isSessionCompleted ? (
              <>
                <div
                  onClick={() =>
                    window.location.assign(`/PracticeSession/Summary/${ptInfo.practiceSessions?.[0]?.id}`)
                  }
                  id={`show_exam_result_${ptInfo.id}`}
                  style={{
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                >
                  <Icon
                    path={mdiViewDashboard}
                    size={0.7}
                    title="Show exam results"
                    vertical
                    color="black"
                  />
                </div>
              </>
              ) : (
                // liveExamStartsAt(ptInfo.liveExamStartsAt) &&
                !liveExamEndsAt(ptInfo.liveExamStartsAt, ptInfo.liveExamEndsAt, liveExamLength) && (
                  <i
                    className="fa fa-play text-success clickable-icon"
                    id={`start_live_exam_${ptInfo.id}`}
                    title="Start Live Exam"
                    onClick={() => {
                      window.location.assign(`/PracticeTemplates/Student/StartLiveExam/${ptInfo.id}`);
                    }}
                  />
                )
              )}
            </>
          ) : (
            <i
              className="fa fa-copy font-size-icon clickable-icon"
              id={`useTemplate_${ptInfo.id}`}
              style={{ color: '#34c73b' }}
              title="Use Template"
              onClick={() => {
                window.location.assign(`/PracticeTemplates/Student/CreatePractice/${ptInfo.id}`);
              }}
            />
          )}
        </>
      )}

      {user?.role === 'instructor' && (
        <>
          {ptInfo.status === 'draft' && (
            <>
              <i
                className="fa fa-pencil font-size-icon clickable-icon"
                id={`edit_${ptInfo.id}`}
                style={{ color: '#29b6f6' }}
                title="Edit practice"
                onClick={() => {
                  window.location.assign(`/PracticeTemplates/${ptInfo.id}/Update`);
                }}
              />

              <i
                className="fa fa-trash font-size-icon clickable-icon"
                title="Delete this practice."
                style={{ color: '#ef5350' }}
                id={`delete_${ptInfo.id}`}
                onClick={() => {
                  confirmAndDeletePracticeTemplate(ptInfo.id, setPracticeTemplateData);
                }}
              />

              <i
                className="fa fa-check-circle font-size-icon clickable-icon"
                title="Release."
                style={{ color: '#34c73b' }}
                id={`release_${ptInfo.id}`}
                onClick={() => {
                  releaseTemplate(ptInfo.id, setPracticeTemplateData);
                }}
              />
            </>
          )}

          <i
            className="fa fa-copy font-size-icon clickable-icon"
            title="Create new practice from this."
            style={{ color: '#34c73b' }}
            id={`dup_template_${ptInfo.id}`}
            onClick={() => {
              window.location.assign(`/PracticeTemplates/${ptInfo.id}/CopyTemplate`);
            }}
          />

          <i
            className="fa fa-download font-size-icon clickable-icon"
            title="Download this practice."
            style={{ color: '#29b6f6' }}
            id={`download_${ptInfo.id}`}
            onClick={() => {
              window.location.assign(`/PracticeTemplates/${ptInfo.id}/pdf`);
            }}
          />

          {ptInfo.status !== 'draft' && (
            <>
              <i
                className="fa fa-eye font-size-icon clickable-icon"
                title="View this template."
                style={{ color: '#333b4d' }}
                id={`view_${ptInfo.id}`}
                onClick={() => {
                  window.location.assign(`/PracticeTemplateViewForm/${ptInfo.id}`);
                }}
              />

              {ptInfo.status === 'released' && (
                <>
                  <i
                    className="fa fa-times font-size-icon clickable-icon"
                    id={`obsolete_${ptInfo.id}`}
                    style={{ color: '#ef5350' }}
                    title="Obsolete"
                    onClick={() => {
                      obsoleteTemplate(ptInfo.id, setPracticeTemplateData);
                    }}
                  />
                  {isLiveExam(ptInfo) && liveExamEndsAt(null, ptInfo.liveExamEndsAt, null) && (
                    <i
                      className="fa fa-dashboard font-size-icon clickable-icon"
                      style={{ color: '#333b4d' }}
                      title="Show exam results"
                      onClick={() => {
                        window.location.assign(`/PracticeTemplates/${ptInfo.id}/summary`);
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const Pt: React.FC<OwnProps & StateProps> = ({ darkMode }) => {
  const [practiceTemplateData, setPracticeTemplateData] = useState<
    PracticeTemplateData[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayedData, setDisplayedData] = useState<PracticeTemplateData[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPracticeTemplateData((data) => {
      setPracticeTemplateData(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const initialData =
      practiceTemplateData.length > 0
        ? practiceTemplateData.slice(0, rowsPerPage)
        : [];
    setDisplayedData(initialData);
  }, [practiceTemplateData, rowsPerPage]);

  const updateDisplayedData = (newPage: number, newRowsPerPage: number) => {
    const startIndex = (newPage - 1) * newRowsPerPage;
    const endIndex = startIndex + newRowsPerPage;
    const newData = practiceTemplateData.slice(startIndex, endIndex);
    setDisplayedData(newData);
    setCurrentPage(newPage);
  };

  const onPageChange = (newPage: number) => {
    updateDisplayedData(newPage, rowsPerPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    updateDisplayedData(1, newRowsPerPage);
  };

  const toCamelCase = (str: String) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderIonCols = (ptInfo: PracticeTemplateData, index: number) => {
    const ionCols = [
      { size: "8", sizeMd: "2", content: ptInfo.name },
      {
        size: "12",
        sizeMd: "2",
        content: ptInfo.examNames.join(", "),
        className: "ion-hide-md-down",
      },
      {
        size: "12",
        sizeMd: "1",
        content: ptInfo.topicName,
        className: "ion-hide-md-down",
      },
      {
        size: "1",
        sizeMd: "1",
        content: ptInfo.totalSections,
        className: "ion-hide-md-down",
      },
      {
        size: "1",
        sizeMd: "1",
        content: ptInfo.totalQuestions,
        className: "ion-hide-md-down",
      },
      {
        size: "12",
        sizeMd: "2",
        content: <StatusBadge ptInfo={ptInfo} />,
        className: "ion-hide-md-down",
      },
      {
        size: "4",
        sizeMd: "1",
        content: <ActionBar ptInfo={ptInfo} setPracticeTemplateData={setPracticeTemplateData} liveExamLength={ptInfo.liveExamLength}/>,
        className: "ion-text-center custom-action-bar",
      },
    ];

    return ionCols.map((col, colIndex) => {
      const isEvenRow = index % 2 !== 0;
      const isCustomWidthColumn = [3, 4].includes(colIndex);
      return (
        <IonCol
          key={index}
          size={isCustomWidthColumn ? "auto" : col.size}
          size-md={isCustomWidthColumn ? "auto" : col.sizeMd}
          className={`${col.className} header-cell bordered-cell custom-col ${
            isEvenRow ? "even-row-background" : "odd-row-background"
          }`}
          style={{
            fontFamily: "Roboto, sans-serif",
            ...(darkMode ? { background: "#1f1f1f", color: "white" } : {}),
          }}
        >
          <div style={{ width: isCustomWidthColumn ? "80px" : "" }}>
            {col.content}
          </div>
        </IonCol>
      );
    });
  };

  const headerColumns = [
    { size: "8", sizeMd: "2", label: "Name", className: "header-cell" },
    {
      size: "12",
      sizeMd: "2",
      label: "Exams",
      className: "header-cell ion-hide-md-down",
    },
    {
      size: "12",
      sizeMd: "1",
      label: "Topic",
      className: "header-cell ion-hide-md-down",
    },
    {
      size: "12",
      sizeMd: "1",
      label: "Total Sections",
      className: "ion-hide-md-down header-cell",
    },
    {
      size: "12",
      sizeMd: "1",
      label: "Total Questions",
      className: "ion-hide-md-down header-cell",
    },
    {
      size: "12",
      sizeMd: "2",
      label: "Status",
      className: "ion-hide-md-down header-cell",
    },
    {
      size: "4",
      sizeMd: "1",
      label: "Actions",
      className: "ion-text-center header-cell",
    },
  ];

  const { user } = useUser();

  return (
    <IonPage id="practice-template-page">
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonTitle>{loading ? "Loading ..." : "Practice Templates"}</IonTitle>

          {!loading && (practiceTemplateData || []).length !== 0 && (
            <>
              { user?.role === 'instructor' && (
                <IonButton
                  className="button-text-normal"
                  color="primary"
                  size="small"
                  slot="end"
                  fill="solid"
                  onClick={() => {
                    window.location.assign(`/PracticeTemplates/Create`);
                  }}
                >
                  Create
                </IonButton>
                )}
            </>
          )}
        </IonToolbar>
      </IonHeader>

      {!loading && practiceTemplateData.length ? (
        <IonContent>
          <IonGrid className="custom-grid large-screen">
            <IonRow className="row-header">
              {headerColumns.map((column, index) => {
                const isCustomWidthColumn = [3, 4].includes(index);

                return (
                  <IonCol
                    key={index}
                    size={isCustomWidthColumn ? "auto" : column.size}
                    size-md={isCustomWidthColumn ? "auto" : column.sizeMd}
                    className={`${column.className} header-cell custom-header-cell`}
                    style={{
                      fontFamily: "Roboto, sans-serif",
                      ...(darkMode
                        ? { background: "#1f1f1f", color: "white" }
                        : {}),
                    }}
                  >
                    <div style={{ width: isCustomWidthColumn ? "80px" : "" }}>
                      {column.label}
                    </div>
                  </IonCol>
                );
              })}
            </IonRow>

            {displayedData.map((ptItem, index) => (
              <IonRow key={index} className="custom-data-row">
                {renderIonCols(ptItem, index)}
              </IonRow>
            ))}
          </IonGrid>
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(practiceTemplateData.length / rowsPerPage)}
            onPageChange={onPageChange}
            rowsPerPage={rowsPerPage}
            darkMode={darkMode}
            handleRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
          />
        </IonContent>
      ) : (
        !loading && (
          <>
            { user?.role === 'instructor' && (
                <IonContent>
                  <div className={darkMode ? "custom-alert-dark" : "custom-alert"}>
                    <div>
                      <h4 className={darkMode ? "alert-text-white" : ""}>
                        Looks like you have not yet created any practice templates!
                      </h4>
                      <p className={darkMode ? "alert-text-white" : ""}>
                        Do you want to create now?
                      </p>
                      <IonButton
                        className="button-text-normal"
                        color="primary"
                        size="small"
                        slot="end"
                        fill="solid"
                        onClick={() => {
                          window.location.assign(`/PracticeTemplates/Create`);
                        }}
                      >
                        Create
                      </IonButton>
                    </div>
                  </div>
                </IonContent>

            )}
              {user?.role === 'student' && (
                <IonContent>
                  <div className={darkMode ? "custom-alert-dark" : "custom-alert"}>
                    <div>
                      <h4 className={darkMode ? "alert-text-white" : ""}>
                        Looks like your institution has not yet created any practice templates.
                      </h4>
                      <p className={darkMode ? "alert-text-white" : ""}>
                        Please request your instructors or wait for them to create new templates.
                      </p>
                    </div>
                  </div>
                </IonContent>
              )}
            </>
        )
      )}
    </IonPage>
  );
};

export default connect<OwnProps, StateProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
  }),
  component: Pt,
});
